import React from 'react'

const donationLinks = {
    marginBottom: '100px'
}
const footer = {
    // position: 'absolute',
    // bottom: '0px',
    marginTop: '150px',
    padding: '20px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignContect: 'center',
}
const disclaimer = {
    width: '100%',
    textAlign: 'center'
}


const Footer = () => {
    return (
        <footer style={footer}> 

              <hr />

              <h3> Donations </h3>

              <p>If I saved you some time today, consider leaving a donation to help me keep the site running.</p>

              <table style={donationLinks}>
                <tbody>
                  <tr>
                    <td>Buy me a coffee:</td>
                    <td><a href="https://www.buymeacoffee.com/nickchubb">https://www.buymeacoffee.com/nickchubb</a></td>
                  </tr>
                  <tr>
                    <td>Ethereum wallet ID:</td>
                    <td>0x5416d62CEcffd91d9CFc67270344f8B139298644</td>
                  </tr>
                  {/* <tr>
                    <td>Bitcoin Wallet ID:</td>
                    <td>link here</td>
                  </tr> */}
                </tbody>
              </table>

              <small style={disclaimer} >All data is property of DriveBC, and is fetched each page refresh.  In case any discrepencies, check out <a href="https://www.drivebc.ca/mobile/pub/events/Highway5.html">the DriveBC website here</a>.</small>
            </footer>
    )
}

export default Footer
