import React from 'react'
import Nav from './Nav';

const headingStyles = {
    marginTop: 0,
    marginBottom: 32,
    width: '100%',
    textAlign: 'center',
}

const Header = () => {
    return (
        <>
            <title>Coquihalla.today -- BC Interior Road Conditions</title>
            <h1 style={headingStyles}>
            Coquihalla.today
            </h1>

            <Nav />

            <hr />
            <br />
        </>
    )
}

export default Header
