import React from 'react'

const navStyle = {
    width: '100%',
    textAlign: 'center',
    marginBottom: '25px'
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center'

}
const navLink = {
    paddingLeft: '5px',
    paddingRight: '5px'
}

const Nav = () => {
    return (
        // <ul style={navStyle}>
        //     <li><a style={navLink} href="/">Home</a></li>
        //     <li><a style={navLink} href="/feed">Feed</a></li>
        //     <li><a style={navLink} href="/about">About</a></li>
        // </ul>
        <nav style={navStyle}>
            <a style={navLink} href="/">Home</a>
            <a style={navLink} href="/feed">Feed</a>
            <a style={navLink} href="/about">About</a>
        </nav>
    )
}

export default Nav
